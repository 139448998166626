.navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.nav {
    border-bottom: 1px solid #055aa0;
    border-top: 1px solid #055aa0;
    line-height: 0;
    margin-bottom: 50px;
    justify-content: center;
    width: 100%;
}

.nav-item {
    padding: 12px 20px 10px !important;
}

.nav-item>a {
    padding: 0 !important;
}

.nav-item a,
.nav-item a:hover {
    font-family: 'Josefin Sans', sans-serif !important;
    color: #055aa0 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    text-decoration: none !important;
    line-height: normal !important;
}

.nav-item a:hover {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
}